<template>
  <div>
    <div class="page-header page-header clear-filter" filter-color="orange">
      <parallax
        class="page-header-image"
        style="background-image:url('img/kaoshijuan_background_of_website4.png')"
      >
      </parallax>
      <div class="container">
        <div class="content-center brand">
          <img class="n-logo" src="img/astro_logo.png" alt="" />
          <h1 class="h1-seo">AstroSoft</h1>
          <h3>Focus on User-interface and AI Development</h3>
          <h5><i class="now-ui-icons location_pin"> Kowloon, Hong Kong</i></h5>
        </div>
        <!-- <h6 class="category category-absolute">
          Hong Kong AstroSoft Ltd.
        </h6> -->
        <h1 class="category category-absolute"><i class="now-ui-icons arrows-1_minimal-down"></i></h1>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
              <h2 class="title">Who we are?</h2>
              <h5 class="description">
                We are a group of programmers who had been working for China internet giants. 
                We all have over 10-year experience on front-end and back-end development. 
                Today, large language models have become mainstream, and many companies and
                 organizations hope to use Gen-AI to improve their customer 
                 management capabilities and improve work efficiency. We are able to 
                 provide services to customers based on open source and commercial large 
                 language models to meet our customs' need.
              </h5>
            </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/kaoshijuan_background_of_website.png')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  "By leveraging AI as a collaborative brainstorming tool, 
                  designers can expedite and diversify the idea generation 
                  and iteration process."
                  <br />
                  <br />
                  <small>-XUAN</small>
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('img/dify-demo.png')"
              ></div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/screenshot-code.png')"
              ></div>
              <h3>
                What can we do for you?
              </h3>
              <p>
                If you need a website built, we can help you select a style 
                from thousands of templates that you'll love. We'll customize 
                it to meet your needs, ensuring it's responsive and compatible 
                with devices like PCs and mobile. We can also use AI to create 
                all the images, icons, and logos, with full copyright ownership 
                transferred to you. Additionally, we'll handle the web acceleration 
                deployment so that your site loads quickly. This way, you can focus 
                on what matters most—your business and market opportunities.
              </p>
              <p>
                If you're interested in optimizing your business processes through 
                artificial intelligence, we can engage with you to provide AI-native 
                solutions based on large language models. We'll work with you to set up 
                and deploy the relevant systems, helping you reduce costs and increase 
                efficiency in the competitive business landscape.
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</template>
<script>
import { Parallax } from '@/components';
import BasicElements from './components/BasicElementsSection';
import Navigation from './components/Navigation';
import TabsSection from './components/Tabs';
import ProgressPagination from './components/ProgressPagination';
import Notifications from './components/Notifications';
import Typography from './components/Typography';
import JavascriptComponents from './components/JavascriptComponents';
import CarouselSection from './components/CarouselSection';
import NucleoIconsSection from './components/NucleoIconsSection';
import SignupForm from './components/SignupForm';
import ExamplesSection from './components/ExamplesSection';
import DownloadSection from './components/DownloadSection';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax
  }
};
</script>
<style></style>
